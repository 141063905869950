import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {

    apiKey: "AIzaSyCX_3cBxRYh73V8GLZeO7CAsKSk_Vd8USg",
  
    authDomain: "taxi-148a7.firebaseapp.com",
  
    databaseURL: "https://taxi-148a7-default-rtdb.europe-west1.firebasedatabase.app",
  
    projectId: "taxi-148a7",
  
    storageBucket: "taxi-148a7.appspot.com",
  
    messagingSenderId: "749456387556",
  
    appId: "1:749456387556:web:7a945af41ba0ac8ef82bf1"
  
  };
  
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);  

export { auth, db }