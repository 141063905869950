import  { useState,useEffect } from 'react'
import logo from '../logo.svg';
import '../App.css';
import { Formik } from 'formik';
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng }  from 'react-google-places-autocomplete';
import axios from 'axios';
import {getDistance, getPreciseDistance} from 'geolib';
import { v4 as uuidv4 } from 'uuid';
import socket from "../components/socket";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Oval } from 'react-loader-spinner';
import { Link } from "react-router-dom";
import { signOut,getAuth } from "firebase/auth"
import { auth, db } from "../components/firebase";
import {  setDoc, doc, updateDoc, getDocs,getDoc, collection } from 'firebase/firestore';
import moment  from"moment";
import { v4 as uuid } from 'uuid'
import Pusher from 'pusher-js';
import Table from 'react-bootstrap/Table';

function DriverList() {
  
  const [user, setUser] = useState();
  
  const [allDriver, setAllDriver] = useState([]);

  const [resas, setResas] = useState([]);

  const auth = getAuth()
  
  const user1 = auth.currentUser; 
  
  
  
  
  async function retrieveDriver(){
    var allDriverTab = [];
   var mel= [];  

   const querySnapshot = await getDocs(collection(db, "user"));
   querySnapshot.forEach((document) => {
    mel.push(document.data());
   })     
 
  setResas(mel); 
 
   
  }

  
  useEffect(() => {  
    retrieveDriver();  
  }, []); 


  function handleClick() {      
    console.log('trf')
  }
 
  return (
    
    <div>

<Table striped bordered hover>
      <thead>
        <tr>
          <th></th>
          <th>Prenom</th>
          <th>Numéro</th>
          <th>Os</th>
          <th>Marque</th>
          <th>Modele</th>
          <th>Actif</th>
          <th>Solde</th>
          
        </tr>
      </thead>
      <tbody>

      {resas.map((todo) => (
       <tr>
          <td><Link
  to={{
    pathname: `/driver/${todo._id}`,
    state: { users: user }
  }}
>
  <button>Voir</button>
</Link></td>
          <td>{todo.LastName}</td>
          <td>{todo.PhoneNumber}</td>
          <td>{todo.Os}</td>
          <td>{todo.Marque}</td>
          <td>{todo.Modele}</td>
          <td>{todo.Actif}</td>
          <td>{todo.Solde}</td>
         
        </tr>
      ))}

      </tbody>
    </Table>

  </div>
  );
}

export default DriverList;
