import  { useState,useEffect } from 'react'

import './App.css';
import ReactDOM from "react-dom/client";
import Courses from './pages/Courses';
import Driver from './pages/Driver';
import DriverList from './pages/DriverList';
import Login from './pages/Login';
import { AuthContext } from './components/AuthContext'
import { Protected } from './components/Protected'
import { createBrowserRouter,RouterProvider } from 'react-router-dom'

function App() {
  const router = createBrowserRouter([
    {
      path:"/",
      element:<Protected><Courses/></Protected>
    },
    {
      path:"/courses",
      element:<Protected><Courses/></Protected>
    },
    {
      path:"/allDriver",
      element:<Protected><DriverList/></Protected>
    },
    {
      path:"/driver/:id",
      element:<Protected><Driver/></Protected>
    },
    {
      path:"/login",
      element:<Login></Login>
    }
  ])

  return (
    <AuthContext>
    <RouterProvider router={router}></RouterProvider>
    </AuthContext>
   
  );
}

export default App;
