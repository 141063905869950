import  { useState,useEffect } from 'react'
import logo from '../logo.svg';
import '../App.css';
import { Formik } from 'formik';
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng }  from 'react-google-places-autocomplete';
import axios from 'axios';
import {getDistance, getPreciseDistance} from 'geolib';
import { v4 as uuidv4 } from 'uuid';
import socket from "../components/socket";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Oval } from 'react-loader-spinner';
import { Link } from "react-router-dom";
import { signOut,getAuth } from "firebase/auth"
import { auth, db } from "../components/firebase";
import {  setDoc, doc, updateDoc, getDocs,getDoc, collection } from 'firebase/firestore';
import moment  from"moment";
import { v4 as uuid } from 'uuid'
import Pusher from 'pusher-js';
import Table from 'react-bootstrap/Table';
import { useParams } from 'react-router';

function Driver() {
  const { id } = useParams();
  const [user, setUser] = useState();
  
  const [allDriver, setAllDriver] = useState([]);

  const [resas, setResas] = useState([]);

  const auth = getAuth()
  
  const user1 = auth.currentUser; 
  
  
  
  
  async function retrieveDriver(){
    var allDriverTab = [];
   var mel= [];  

   const querySnapshot = await getDoc(doc(db, "user", id));
  // console.log('querySnapshot',querySnapshot.data());
   mel.push(querySnapshot.data());
   const userPromises = mel.map((data) =>    
   getDoc(doc(db, "documents_statut", data._id))
  );

  var mel1 = []

  const userDocs = await Promise.all(userPromises);
  userDocs.forEach((document) => {
    var yas = document.data();
    yas.id = document.id;
   
    mel1.push(yas);
  })
    

  let merged = [];

  for(let i=0; i<mel.length; i++) {
    merged.push({
    ...mel[i], 
    ...(mel1.find((itmInner) => itmInner.id === mel[i]._id))}
    );
  }
  setResas(merged); 
  //console.log(merged);
   
  }

  
  useEffect(() => {  
    retrieveDriver();  
  }, []); 


  function handleClick() {      
    console.log('trf')
  }
 
  return (
    
    <div>

<Table striped bordered hover>
      <thead>
        <tr>
          <th>Prenom</th>
          <th>Numéro</th>
          <th>Os</th>
          <th>Actif</th>
          <th>Solde</th>
          <th>Ads</th>
          <th>Assurance</th>
          <th>Carte grise</th>
          <th>Carte taxi</th>
          <th>Permis</th>
          <th>Rc PRo</th>
        </tr>
      </thead>
      <tbody>

      {resas.map((todo) => (
       <tr>

          <td>{todo.LastName}</td>
          <td>{todo.PhoneNumber}</td>
          <td>{todo.Os}</td>
          <td>{todo.Actif}</td>
          <td>{todo.Solde}</td>
          <td>{todo.ads}</td>
          <td>{todo.assurance}</td>
          <td>{todo.carteGrise}</td>
          <td>{todo.carteTaxi}</td>
          <td>{todo.permis}</td>
          <td>{todo.rcpro}</td>
        </tr>
      ))}

      </tbody>
    </Table>

  </div>
  );
}

export default Driver;
