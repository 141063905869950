import  { useState,useEffect } from 'react'
import logo from '../logo.svg';
import '../App.css';
import { Formik } from 'formik';
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng }  from 'react-google-places-autocomplete';
import axios from 'axios';
import {getDistance, getPreciseDistance} from 'geolib';
import { v4 as uuidv4 } from 'uuid';
import socket from "../components/socket";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Oval } from 'react-loader-spinner';
import { Link } from "react-router-dom";
import { signOut,getAuth } from "firebase/auth"
import { auth, db } from "../components/firebase";
import {  setDoc, doc, updateDoc, getDoc,addDoc, collection } from 'firebase/firestore';
import moment  from"moment";
import { v4 as uuid } from 'uuid'
import Pusher from 'pusher-js';
import Pubnub from 'pubnub';

const pubnub = new Pubnub({
  publishKey: "pub-c-7efee5e7-b6bd-4802-a6fd-bf3e96a85b88",
  subscribeKey: "sub-c-340597c7-cb27-4cd9-8f20-44b6f7299b2b",
  userId: "yas0507",
});

function Courses() {
  const [depart, setDepart] = useState(null);
  const [arrivee, setArrivee] = useState(null);
  const [user, setUser] = useState();
  const [pre, setPre] = useState('yas');
  const [phone, setPhone] = useState('');
  const [emailClient, setEmailClient] = useState('');
  const [loader, setLoader] = useState(false);

  const [immatDriver, setImmatDriver] = useState();
  const [marqueDriver, setMarqueDriver] = useState();
  const [modeleDriver, setModeleDriver] = useState();
  const [phoneDriver, setPhoneDriver] = useState();

  const [originLatitute, setOriginLatitute] = useState();
  const [originLongitude, setOriginLongitude] = useState();

  const auth = getAuth()
  
  const user1 = auth.currentUser;
  
  const [messages, setMessages] = useState([]);
  const [text, onChangeText] = useState("");
  
  //console.log('user1',auth);
  console.log('user1',auth.currentUser.email);

  const retrieveUser = async () => {
    //console.log('on rentre')
    getDoc(doc(db, "user", 'dQsOMZXzOPgUwMF0b5zvX7nGfXG2')).then(docSnap => {
     // console.log(docSnap);        
      if (docSnap.exists()) {
          setUser(docSnap.data());  
          setPre('Hôtel '+docSnap.data().Hotel);
          setPhone(docSnap.data().PhoneNumber);
          setEmailClient(user1.email)
          //console.log("exis",docSnap.data());           
        } 
      })  

  }
  
  useEffect(() => {
  
    retrieveUser();
  
  }, []);

  {/**
  useEffect(() => {
    // Create a local channel entity
    const channel = pubnub.channel('allRide');
    // Create a subscription on the channel
    const subscription = channel.subscription();

    // add an onMessage listener to the channel subscription
    subscription.onMessage = (messageEvent) => {
        setMessages((messages) => [...messages, messageEvent.message.description]);
    };

    // subscribe to the channel
    subscription.subscribe();

    // Cleanup function to remove subscription and listener
    return () => {
        subscription.unsubscribe();
        pubnub.removeListener({ message: subscription.onMessage });
    }
}, []);
*/}


  useEffect(() => {
    {/**
    const pusher = new Pusher('ea257f18d5ae3cde7ce6', {
      authEndpoint: 'https://solutionclic.fr:4000/pusher/auth',
      cluster: 'eu',
      encrypted: true  
    });

    const channel = pusher.subscribe("my-channel");
    channel.trigger("client-my-event",  {
      message: "hello world"
     });

   // pusher.trigger("my-channel", "my-event", { message: "hello world" });

    
    pusher.trigger("my-channel", "my-event", {
      message: "hello world"
    });
    
    const available_drivers_channel = pusher.subscribe('private-ride-yas'); // subscribe to "available-drivers" channel
      

    available_drivers_channel.bind('pusher:subscription_succeeded', () => {
      console.log('yas')
    })

    available_drivers_channel.trigger('client-driver-request', {

      username: 'aldo',

      pickup: 'champs élysee',

      dropoff: 'cdegh'

    });
     
    const channel = pusher.subscribe("my-channel");
    channel.bind("my-event", (data) => {
      console.log(data);
     }); 

     return () => {
      pusher.unsubscribe("my-channel");
    };*/}

}, []);

useEffect(() => {     
  {/**
  const pusher = new Pusher('ea257f18d5ae3cde7ce6', {
    authEndpoint: 'https://solutionclic.fr:4000/pusher/auth',
    cluster: 'eu',
    //encrypted: true  
  });

  pusher.unsubscribe("private-ride-yas");

  
  const user_ride_channel = pusher.subscribe('private-ride-yas');

  user_ride_channel.bind('pusher:subscription_succeeded', () => {
    console.log('driver connect to channel sg yas')  
  

  });  

  user_ride_channel.bind('client-driver-response', (driver_response) => {
    console.log('driver_response',driver_response.available)
    user_ride_channel.trigger('client-driver-response', {
      taxi: 'dispo',      
    });

  })
  

  
    
  
  const available_drivers_channel = pusher.subscribe('private-my-channel'); // subscribe to "available-drivers" channel      

  available_drivers_channel.bind('pusher:subscription_succeeded', () => {
    console.log('driver connect to channel sg')

    available_drivers_channel.trigger('client-response-course', {
      response: 'dispo yas'
      })

  }) */}

  

}, []);

  function handleClick() {
   
   
    console.log('trf')
  }

  const notify = () => toast.success('Course acceptée!', {
    position: "bottom-right",
    autoClose: 7000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",

    });

  useEffect(() => {

    socket.on("no-available", retour => {
      
      setLoader(false);
      toast.error('Pas de taxis!', {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    
        });
    })  

    socket.on("response-course", retourResponse => {
      // il y a immat du chauffeur dans la string
      setLoader(false);
      setImmatDriver(retourResponse.immatriculation);
      setMarqueDriver(retourResponse.marque);
      setModeleDriver(retourResponse.modele);
      setPhoneDriver(retourResponse.numero);
      console.log('retourResponse yas', retourResponse)
      console.log('retourResponse yas', typeof retourResponse.immatriculation)
      toast.success('Course acceptée!', {
        position: "bottom-right",
        autoClose: 7000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    
        });
        
      //navigation.navigate('Course',{course:retourResponse})    
        
    })

  }, [socket]);  


  const calculateDistance = async () => {
    var dis = getDistance(
      {latitude: 48.872564, longitude: 2.304271},
      {latitude: 48.870377, longitude: 2.312401},
    );

    console.log('dis',dis)
    
  };

  const changeGoo = async(adr) =>{
   
    const results = await geocodeByAddress(adr.label);
    const latLng = await getLatLng(results[0]);
    setDepart(adr.label)    
    setOriginLatitute(latLng.lat)
    setOriginLongitude(latLng.lng)
    
  }

  async function handleSignOut(){
    try {
        await signOut(auth);
    } catch (error) {
        console.log(error)
    }
  }  
 
  return (
    
    <div>

    <Formik

      initialValues={{ email: '', prenom: '', numero: '' }}

      validate={values => {

        const errors = {};

        if (!values.email) {

          errors.email = 'Required';

        } else if (

          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)

        ) {

          errors.email = 'Invalid email address';

        }

        return errors;

      }}

      onSubmit={(values, { setSubmitting }) => {
        console.log('send server')
        setTimeout(() => {
          
          const formValue = JSON.stringify(values); 
          //var seq = (Math.floor(Math.random() * 10000) + 10000).toString().substring(1); 
         // alert(JSON.stringify(values, null, 2));
        

          const idCourse = uuidv4();
          
          const location = {
            latitude: originLatitute,
            longitude: originLongitude,
            depart: depart,
            arrivee: arrivee.label,
            userId: 100,
            cate: 'BERLINE',
            idCourse: idCourse, 
            lastname: values.prenom,
            phone: values.numero,
            distanceCourse: null   
          }

          socket.emit('requestTaxi',{
            location
          })  

          addDoc(collection(db, "course"), {      
             depart: depart,
            arrivee: arrivee.label,
            //dateCourse: moment(),
            clientId: auth.currentUser.uid,
            driverId: '',
            statut: 'En cours',
            idCourse: uuid(),    
            });  

          setLoader(true);
          //console.log('loci',location)

          {/*
          


          geocodeByAddress('25 rue des Jeuneurs, Paris, France')
          .then(results => getLatLng(results[0]))
          .then(({ lat, lng }) =>
            console.log('Successfully got latitude and longitude', { lat, lng })
            
          ); */}

          

          setSubmitting(false);

        }, 400);

      }}

    >

      {({

        values,

        errors,

        touched,

        handleChange,

        handleBlur,

        handleSubmit,

        isSubmitting,

        /* and other goodies */

      }) => (
        <div className="container">

        <h1 className='text'>Nouvelle course</h1>  

        <div>
        
        <ToastContainer
position="bottom-right"
autoClose={7000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"

/>
      </div>

        <form onSubmit={handleSubmit}>
        <div className="form-row">
          
          <div className="input-data">
            <label htmlFor="arrivee">Prénom ou nom de société: </label>
            <input              
              type="text"

              name="prenom"

              id="prenom"

              onChange={handleChange}

              onBlur={handleBlur}

              value={values.prenom}

            />

            {errors.prenom && touched.prenom && errors.prenom}
          </div>

          <div className="input-data">
          <label htmlFor="numero">Numéro de téléphone: </label>
          <input

            type="text"

            name="numero"

            id="numero"

            onChange={handleChange}

            onBlur={handleBlur}

            value={values.numero}

          />

          {errors.numero && touched.numero && errors.numero}
          </div> 

          <div className="input-data">
            <label htmlFor="email">Email: </label>
            <input

              type="email"

              name="email"
              id="email"
              onChange={handleChange}

              onBlur={handleBlur}

              value={values.email}

            />

            {errors.email && touched.email && errors.email}
          </div>
            
            <div className="input-data">
            <label >Départ: </label>  

            <GooglePlacesAutocomplete
            apiKey="AIzaSyChuiAP4YPZEyjIOE5nf3tDGkO4pwMPlvo"
            selectProps={{
              depart,
              onChange: changeGoo,
            }}
            />
            </div>
          
                  

          <div className="input-data">
            <label htmlFor="arrivee">Arrivée: </label>

            <GooglePlacesAutocomplete
              apiKey="AIzaSyChuiAP4YPZEyjIOE5nf3tDGkO4pwMPlvo"
              selectProps={{
                arrivee,
                onChange: setArrivee,
              }}
            />
          </div> 
          
          { auth.currentUser.email == 'bouchra.bencheikh@icloud.com' || auth.currentUser.email == 'aldo@gmail.com' &&
                        <div className = "alert alert-warning" role = "alert">
                          <Link
                          to={{
                          pathname: `/allDriver`,

                          }}
                          > <button>Driver</button></Link>
                          
                        </div>
                    }       
        
        

          <div className="form-row submit-btn">
            <div className="input-data"> 
              <div className="inner"></div>
                <button type="submit" disabled={isSubmitting}>
                  Envoyer
                </button>              
              </div>    
             
              <Oval
              visible={loader}
              height="50"
              width="50"
              color="#4fa94d"
              ariaLabel="oval-loading"
              wrapperStyle={{}}
              wrapperClass=""
              />
          <div className='container-taxi'>Immatriculation du taxi: <span className='label-taxi'>{immatDriver}</span></div>
          <div className='container-taxi'>Numéro du taxi: <span className='label-taxi'>{phoneDriver}</span></div>
          <div className='container-taxi'>Marque du véhicule: <span className='label-taxi'>{marqueDriver}</span></div>
          <div className='container-taxi'>Modéle du véhicule: <span className='label-taxi'>{modeleDriver}</span></div>
          </div>    

        </div>    
        </form>
        </div>
      )}

    </Formik>
      
    <button onClick={() => {handleSignOut()}}>Déconnexion</button>

    <div>
    <button onClick={() => {handleSignOut()}}>Send</button>

{messages.map((message, idx) => (
    <div>{message}</div>
))}
</div>  


  </div>

   

  );
}

export default Courses;
